<template>
  <main class="container">
      <h1>{{ $root.bridgeName.length ? $root.bridgeName : 'Nexa Bridge X' }} <span v-if="showTitle"><span style="color:#3498db;">/</span> {{pageTitle}}</span></h1>
      
      <div v-if="!$root.loggedIn || !$root.hasSelectedBridge">
        <LoginView />
      </div>
      <div v-else>
        <div style="border-top:4px solid #34495e;width:200px;height:1px;margin-top:30px;"></div>
        {{bridgeName}}
        <div style="margin-top:25px;">
          <router-view />
        </div>

        <div style="margin-top:2em;font-size:12px;text-align:right;cursor:pointer;" @click="logout">Logga ut</div>
      </div>
  </main>

<!--  
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
    </div>
    <router-view/>
  </div>-->
</template>

<script>
import LoginView from './views/Login.vue'

export default {
  name: 'App',
  components: {
    LoginView
  },
  data: () => ({
    bridgeName: '',
    pageTitle: ''
  }),
  created () {

  },
  methods: {
    async logout () {
      await localStorage.removeItem('token')

      this.$root.loggedIn = false
      this.$root.selectedBridge = ''
      this.$root.bridgeName = ''
    }
  },
  computed: {
    title () {
      const name = this.$route.name
      if (this.$route.path === '/') return null
      return name
    },

    showTitle () {
      return this.pageTitle.length > 0
    }
  }
}
</script>

<style scoped>
  
body {
  font-size:20px !important;
}
h1 {
  margin-bottom: 10px;
  font-size:50px;
}
</style>
