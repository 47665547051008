<template>
  <div class="home">
    <div v-if="loading">
      <div aria-busy="true"></div>
    </div>
    <div v-else>
      <p>Börja med att välja ett rum här nedan. Välj sedan en av enheterna i detta rum för att få upp fler alternativ.</p>
      <select v-model="selectedRoomId" @change="didChangeRoom">
        <option value="" selected disabled>Välj ett rum...</option>
        <option v-for="room in rooms" :key="room.id" :value="room.id">{{ room.name }}</option>
      </select>

      <div v-if="rooms.length == 0 || selectedRoomId.length">
        <select v-model="selectedNodeId" @change="didChangeNode">
          <option value="" disabled>Välj en enhet...</option>
          <option v-for="node in nodes.filter(x => x.roomId === selectedRoomId)" :key="node.id" :value="node.id">{{ node.name }}</option>
        </select>
      </div>

      <div v-if="selectedNodeId">
        <h5>{{ node.name }} <span v-if="selectedNodeCapability"><span  style="color:#3498db;">/</span> {{ capName }}</span></h5>

        <div v-if="!sensorData.length && !loadingSensorData">
          <p>Här nedan väljer du vilken typ du vill ha ut din data för. Du kan bara välja en varje gång.</p>
          <div class="flexContainer">
            <div v-for="cap in node.capabilities.filter(x => allowedCapsKeys.includes(x))" :key="cap" @click="selectCapability(cap)">{{allowedCapabilities[cap]}}</div>
          </div>
        </div>
        <div v-else>
          <div v-if="loadingSensorData">
            <div aria-busy="true"></div>
          </div>
          <div v-else>
            <div class="grid">
              <div class="box">
                <p>Antal dagar du vill få ut datan för. Standard är 7 dagar.</p>

                <select v-model="days" @change="didChangeIntervalOrDay">
                  <option value="" disabled>Välj dagar</option>
                  <option v-for="i in 30" :value="i" :key="i">{{ i }}</option>
                </select>
              </div>

              <div class="box">
                <p>Intervall. Välj mellan 5, 15, 30 eller 60 minuter.</p>

                <select v-model="interval" @change="didChangeIntervalOrDay">
                  <option value="" disabled>Välj intervall</option>
                  <option value="5">5 minuter</option>
                  <option value="15">15 minuter</option>
                  <option value="30">30 minuter</option>
                  <option value="60">60 minuter</option>
                </select>
              </div>
            </div>
            <p>Din data, de senaste {{ days }} dagarna i {{ interval }} minutersintervaller. Om du vill kan du ändra intervall nedan. Välj mellan 5 minuter, 15 minuter, 30 minuter eller 60 minuter.</p>

            <button style="background-color:#094168;border:1px solid #094168;" @click="download">Ladda ner CSV</button>
            <pre style="padding:12px;margin-top:12px;">{{sensorDataOut}}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data: () => ({
    loading: true,
    nodes: [],
    rooms: [],
    allowedCapabilities: {
      power: 'Energiförbrukning (W)',
      //meter: 'Energiförbrukning (kWh)',
      temperature: 'Temperatur',
      humidity: 'Luftfuktighet',
      luminance: 'Luminans',
      battery: 'Batteri %'
    },
    selectedRoomId: '',
    selectedNodeId: '',
    selectedNodeCapability: '',
    sensorData: '',
    sensorDataOut: '',
    loadingSensorData: false,
    interval: 5,
    days: 7
  }),
  async created () {
    const json = await this.fetch(`/info/init`)
    const data = json.data

    const capKeys = this.allowedCapsKeys

    this.nodes = data.nodes
      .filter(x => x.capabilities.some(cap => capKeys.includes(cap)))
      .sort((a, b) => a.name.localeCompare(b.name))
    this.rooms = data.rooms
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(x => {
        return this.nodes.some(node => node.roomId === x.id)
      })


    this.loading = false
  },
  computed: {
    allowedCapsKeys () {
      return Object.keys(this.allowedCapabilities)
    },

    node () {
      return this.nodes.find(x => x.id === this.selectedNodeId)
    },

    capName () {
      return this.allowedCapabilities[this.selectedNodeCapability]
    }
  },
  methods: {
    didChangeNode () {
      this.selectedNodeCapability = ''  
      this.sensorData = ''
      this.loadingSensorData = false
    },

    didChangeRoom () {
      this.selectedNodeId = ''
      this.selectedNodeCapability = ''
    },

    didChangeIntervalOrDay () {
      this.selectCapability(this.selectedNodeCapability)
    },

    async selectCapability (cap) {
      this.selectedNodeCapability = cap
      this.loadingSensorData = true

      await this.fetchSensorData()

      let out = ''
      for (const line of this.sensorData.split('\n')) {
        if (line.length < 5) continue

        const split = line.split(',')
        const date = new Date(parseInt(split[0]))
        const value = parseFloat(split[1])

        out += `${date.toISOString()},${value}\n`
      }

      this.sensorDataOut = out

      this.loadingSensorData = false
    },

    download () {
      var hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:attachment/text,' + encodeURI(this.sensorDataOut)
      hiddenElement.target = '_blank'
      hiddenElement.download = `${this.node.name.toLowerCase()}-${this.capName.toLowerCase()}.csv`
      hiddenElement.click()
    },

    async fetchSensorData () {
      const json = await this.fetch(`/energy/${this.selectedNodeId}/${this.selectedNodeCapability}?days=${this.days}&finterval=${this.interval}`, 'text')

      this.sensorData = json
    },

    async fetch (path, type = 'json') {
      const bridgeId = this.$root.selectedBridge


      const token = localStorage.getItem('token')

      let headers = {
          Accept: 'application/json'
      }

      if (token) {
        headers.Authorization = `Basic ${btoa(`X:${token}`)}`
      }

      const res = await fetch(`https://api.nexabridge.se/to/${bridgeId}/v1${path}`, {
        headers: headers
      })

      const json = await res[type]()
      return json
    }
  }
}
</script>

<style scoped>
.flexContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.flexContainer div {
  width: 100%;
  height: 85px;
  background-color: #34495e;
  border-radius:4px;
  padding:12px;
  padding-top:30px; 
  text-align:center;
  font-size:15px;
  color:#FFF;
}

.flexContainer div:hover {
  cursor: pointer;
  background-color: #496684;
}

.box {
  padding:18px;
  background-color:#f5f5f5;
  border-radius:4px;
  margin-bottom:18px;
}


</style>