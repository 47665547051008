import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Hem',
    component: Home
  }
]

const router = new VueRouter({
  routes
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
      document.title = 'Nexa Bridge Export'
  });
});

export default router
