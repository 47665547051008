import Vue from 'vue'
import App from './App.vue'
import router from './router'

require('@/assets/pico.min.css')

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  data: () => ({
    loggedIn: false,
    selectedBridge: '',
    bridgeName: ''
  }),
  created () {
    if (this.loginToken !== null) {
      this.loggedIn = true
    }
  },
  computed: {
    loginToken () {
      return localStorage.getItem('token')
    },
    
    hasSelectedBridge () {
      return this.selectedBridge.length > 0
    }
  }
}).$mount('#app')
